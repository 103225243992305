<template>
  <div class="hello">
    <!-- Preloader Start-->
    <div id="preloader-active">
      <div class="preloader d-flex align-items-center justify-content-center">
        <div class="preloader-inner position-relative">
          <div class="text-center">
            <div class="loader"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-top header-top-green">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6 d-none d-md-block"><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </span></div>
          <div class="col-lg-6 col-md-6 text-end"><a class="text-body-text line-right" href="#">(+01) - 456 789</a><a class="text-body-text" href="mailto:contact@ypodo.com">contact@ypodo.com</a></div>
        </div>
      </div>
    </div>
    <header class="header sticky-bar">
      <div class="container">
        <div class="main-header">
          <div class="header-left">
            <div class="header-logo"><a class="d-flex" href="/"><img alt="Ypodo" style="max-width:80px;" src="/assets/imgs/template/logo.png"></a></div>
            <div class="header-nav">
              <nav class="nav-main-menu d-none d-xl-block">
                <ul class="main-menu">
                  <li><a class="active" href="/">Accueil</a>
                  </li>
                  <li>
					<a v-if="(isloggedin == 1) && (isgeolocalized == 1)" href="/compte">Mon compte</a>
					<a v-else-if="(isloggedin == 1) && (isgeolocalized == 0)" v-on:click="getCurrentPosition()" href="/compte">Mon compte</a>
					<a v-else-if="(isloggedin == 0) && (isgeolocalized == 1)" v-on:click="setDestination('/compte');" href="/login">Mon compte</a>
					<a v-else-if="(isloggedin == 0) && (isgeolocalized == 0)" v-on:click="setDestination('/compte');" href="/login">Me Connecter</a>
					</li>				  
                  <li>
					<a v-if="(isloggedin == 1) && (isgeolocalized == 1)" href="/signalements">Mes signalements</a>
					<a v-else-if="(isloggedin == 1) && (isgeolocalized == 0)" v-on:click="getCurrentPosition()" href="/signalements">Mes signalements</a>
					<a v-else-if="(isloggedin == 0) && (isgeolocalized == 1)" v-on:click="setDestination('/signalements');" href="/login">Mes signalements</a>
					<a v-else-if="(isloggedin == 0) && (isgeolocalized == 0)" v-on:click="setDestination('/signalements');" href="/login">Mes signalements</a>					
					</li>
                  <li>
					<a v-if="(isloggedin == 1) && (isgeolocalized == 1)" href="/signaler">Signaler</a>
					<a v-else-if="(isloggedin == 1) && (isgeolocalized == 0)" v-on:click="getCurrentPosition()" href="/signaler">Signaler</a>
					<a v-else-if="(isloggedin == 0) && (isgeolocalized == 1)" v-on:click="setDestination('/signaler');" href="/login">Signaler</a>
					<a v-else-if="(isloggedin == 0) && (isgeolocalized == 0)" v-on:click="setDestination('/signaler');" href="/login">Signaler</a>					
				</li>
                  <li>
					<a v-if="(isloggedin == 1) && (isgeolocalized == 1)" href="/inhabitant">Mes Communes préférées</a>
					<a v-else-if="(isloggedin == 1) && (isgeolocalized == 0)" v-on:click="getCurrentPosition()" href="/inhabitant">Mes Communes préférées</a>
					<a v-else-if="(isloggedin == 0) && (isgeolocalized == 1)" v-on:click="setDestination('/inhabitant');" href="/login">Mes Communes préférées</a>
					<a v-else-if="(isloggedin == 0) && (isgeolocalized == 0)" v-on:click="setDestination('/inhabitant');" href="/login">Mes Communes préférées</a>					
				</li>  
                </ul>
			</nav>
              <div class="burger-icon burger-icon-white"><span class="burger-icon-top"></span><span class="burger-icon-mid"></span><span class="burger-icon-bottom"></span></div>
            </div>
          </div>
          <div class="header-right">
            <div class="block-signin">
				<a class="btn btn-default hover-up icon-arrow-right" v-on:click="setDestination('/compte');" href="/createaccount">Inscription</a>
			
			</div>
          </div>
        </div>
      </div>
    </header>
    <div class="mobile-header-active mobile-header-wrapper-style perfect-scrollbar">
      <div class="mobile-header-wrapper-inner">
        <div class="mobile-header-top">
          <div class="user-account"><img src="assets/imgs/template/ava_1.png" alt="Ypodo">
            <div class="content">
              <h6 class="user-name">Hi <span class="text-brand">Steven !</span></h6>
              <p class="font-xs text-muted">You have 5 new messages</p>
            </div>
          </div>
        </div>
        <div class="mobile-header-content-area">
          <div class="perfect-scroll">       
            <div class="mobile-menu-wrap mobile-header-border">
              <!-- mobile menu start-->
              <nav>
                <ul class="mobile-menu font-heading">
                  <li class="has-children"><a class="active" href="index.html">Home</a>
                    <ul class="sub-menu">
                      <li><a href="index.html">Homepage - 1</a></li>
                      <li><a href="index-2.html">Homepage - 2</a></li>
                      <li><a href="index-3.html">Homepage - 3</a></li>
                      <li><a href="index-4.html">Homepage - 4</a></li>
                      <li><a href="index-5.html">Homepage - 5</a></li>
                      <li><a href="index-6.html">Homepage - 6</a></li>
                      <li><a href="index-7.html">Homepage - 7</a></li>
                      <li><a href="index-8.html">Homepage - 8</a></li>
                    </ul>
                  </li>
                  <li class="has-children"><a href="page-about-1.html">About</a>
                    <ul class="sub-menu">
                      <li><a href="page-about-1.html">About Us - 1</a></li>
                      <li><a href="page-about-2.html">About Us - 2</a></li>
                      <li><a href="page-about-3.html">About Us - 3</a></li>
                    </ul>
                  </li>
                  <li class="has-children"><a href="page-service-1.html">Company</a>
                    <ul class="sub-menu">
                      <li><a class="closer" href="page-service-1.html">Services - 1</a></li>
                      <li><a class="closer" href="page-service-2.html">Services - 2</a></li>
                      <li class="hr"><span></span></li>
                      <li><a class="closer" href="page-pricing-1.html">Pricing - 1</a></li>
                      <li><a class="closer" href="page-pricing-2.html">Pricing - 2</a></li>
                      <li class="hr"><span></span></li>
                      <li><a class="closer" href="page-faqs-1.html">FAQs - 1</a></li>
                      <li><a class="closer" href="page-faqs-2.html">FAQs - 2</a></li>
                      <li class="hr"><span></span></li>
                      <li><a class="closer" href="page-career.html">Career</a></li>
                      <li><a class="closer" href="page-career-detail.html">Career Detail</a></li>
                    </ul>
                  </li>
                  <li class="has-children"><a href="#">Pages</a>
                    <ul class="sub-menu">
                      <li><a href="page-contact.html">Contact</a></li>
                      <li><a href="page-signup.html">Sign Up</a></li>
                      <li><a href="page-login.html">Log In</a></li>
                      <li><a href="page-reset.html">Reset Password</a></li>
                      <li><a href="404.html">Error 404</a></li>
                    </ul>
                  </li>
                  <li class="has-children"><a href="blog-1.html">Blog</a>
                    <ul class="sub-menu">
                      <li><a class="closer" href="blog-1.html">Blog 1</a></li>
                      <li><a class="closer" href="blog-2.html">Blog 2</a></li>
                      <li class="hr"><span></span></li>
                      <li><a href="blog-single.html">Blog Single</a></li>
                    </ul>
                  </li>
                  <li class="has-children"><a href="#">Shop</a>
                    <ul class="sub-menu">
                      <li><a class="closer" href="page-shop-grid-1.html">Shop Grid 1</a></li>
                      <li><a class="closer" href="page-shop-grid-2.html">Shop Grid 2</a></li>
                      <li><a class="closer" href="page-shop-single.html">Product Details</a></li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <!-- mobile menu end-->
            </div>
            <div class="mobile-account">
              <h6 class="mb-10">Your Account</h6>
              <ul class="mobile-menu font-heading">
                <li><a href="#">Profile</a></li>
                <li><a href="#">Work Preferences</a></li>
                <li><a href="#">My Boosted Shots</a></li>
                <li><a href="#">My Collections</a></li>
                <li><a href="#">Account Settings</a></li>
                <li><a href="#">Go Pro</a></li>
                <li><a href="page-login.html">Sign Out</a></li>
              </ul>
            </div>
            <div class="site-copyright color-gray-400">Copyright 2022 &copy; Ypodo - Agency Template. <br>Designed by<a href="http://alithemes.com">&nbsp; AliThemes</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    isloggedin: {
      type: Number,
      default: 0
    },
    isgeolocalized: {
      type: Number,
      default: 0
    }	
  },
  data() {
    return{ 
		destination:'/signaler',
		}
  }, 
	methods: { 
	setDestination(d){
		this.destination = d
		localStorage.setItem('destination',this.destination );
	},  
    getCurrentPosition(){
			this.$emit('activegeoloc');
    },	
   },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
