<template> 
<div class="page-content header-clear-small">
        <div style="height:100px">
        </div> 
    <div class="card card-style login">
        <div class="content mb-0 text-center">
            <h4 class="text-uppercase font-600 mb-2 font-14">Connectez-vous</h4>
            <div class="divider bg-orange-dark-sdw mb-3"></div>
            <div class="input-style has-icon input-style-1 input-required">
               <i class="input-icon fa fa-user font-16 mt-3 color-blue-dark-sdw"></i>
                
                <em>(obligatoire)</em>
                <input type="text" name="username" autocomplete="chrome-off" :class="loginError.username ? 'form-control is-invalid':''" v-model="input.username" placeholder="Email">
            </div> 
            <div class="input-style has-icon input-style-1 input-required">
               <i v-if="passwordFieldType == 'password'" class="input-icon fa fa-eye font-16 mt-3 color-green-dark-sdw" @click.prevent="switchVisibility"></i>
               <i v-else class="input-icon fa fa-eye-slash font-16 mt-3 color-red-dark-sdw" @click.prevent="switchVisibility"></i>
                
                
                <em>(obligatoire)</em>
                <input :type="passwordFieldType" name="password" autocomplete="chrome-off" :class="loginError.password ? 'form-control is-invalid':''" v-model="input.password" placeholder="Mot de passe">
            </div>   
            <div class="row">
                <div class="col-6">
                    <a href="/forgot" data-menu="menu-forgot" class="font-16">Mot de passe oublié?</a>
                </div>
                <div class="col-6">
                    <a data-menu="menu-signup" href="/createaccount" class="float-right font-16">Créer un compte</a>
                    <div class="clearfix"></div>
                </div>
            </div>
			<button type="button" class="btn btn-full btn-m shadow-l rounded-s font-500 bg-orange-dark-sdw mt-4 mb-4 color-white nice-btn" v-on:click="login()">Connexion</button>
		</div>
            
    </div>
</div>
</template>

<script>

import { Plugins } from '@capacitor/core'
import AppConstants from '@/config'
// @ is an alias to /src
export default {
  data() {
    return{ 
		passwordFieldType: 'password',
		loading: true,
		newUser: {},
		errors: {},
		input: {
                    username: "",
                    password: ""
        },
		loginError: {
                    username: false,
                    password: false
        },		
		user: {}
		}
  }, 
  props: {
    isloggedin: {
      type: Number,
      default: 5
    }
  },   
  components: {},
  mounted() {
  }, 
  methods: {
	switchVisibility() {
		this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
    },   
	getConst(key){
		const Numbers = {
				API_URL : AppConstants.API_URL,
				API_TOKEN : AppConstants.API_TOKEN,
				API_ID: AppConstants.API_ID,
		};
        return Numbers[key];	
	},
	login() {
                if(this.input.username != "" && this.input.password != "") {
                    var token = this.getConst('API_TOKEN');
					var id = this.getConst('API_ID');
					var apiUrl = this.getConst('API_URL')+"login"
				
					var apiData = { 
					"email": this.input.username,
					"password": this.input.password,
					"role" : "ROLE_INHABITANT",
					"token" : token,
					"appid": id
					}
					let axios = require('axios');
					axios.post(apiUrl, apiData)
					.then(response => {
						if (response.data.message == 'OK') {
						this.user = response.data.data	
						localStorage.setItem('token', this.user.api_token )
						localStorage.setItem('id', this.user.id )
						localStorage.setItem('email', this.user.email )
						localStorage.setItem('firstname', this.user.firstname )
						localStorage.setItem('name', this.user.name )
						localStorage.setItem('address', this.user.address )
						localStorage.setItem('zip', this.user.zip )
						localStorage.setItem('city', this.user.city )
						this.loginError.username = false;
						this.loginError.password = false;
                        this.userisloggedin()
						this.$emit('loginaccount', '1')
						this.getCurrentPosition()
						var destination = localStorage.getItem('destination')
						if (destination) {
						location.href = destination
						} else {
						location.href = '/signaler'
						}
						} else {
						this.loginError.username = true;
						this.loginError.password = true;
						}
				})
				.catch(error => {
					console.log(JSON.stringify(error));
					this.loginError.username = true;
					this.loginError.password = true;
				})	
                } else {
					this.loginError.username = true;
					this.loginError.password = true;
                }
	},
	closeMenu(id){
		document.getElementById(id).style.opacity = '0'		
		document.getElementById(id).style.bottom =  "40px";
		document.getElementById(id).classList.remove("menu-active");
		document.getElementById('footer-bar').classList.remove("footer-menu-hidden");
		document.body.classList.remove('modal-open'); 
		var elems = document.querySelectorAll(".menu-hider");
		[].forEach.call(elems, function(el) {
				el.classList.remove("menu-active");
				el.classList.remove("menu-active-clear");
		});	
		if (id == 'menusignaler') {
		this.resetFileUpload();		
		}
		
	},
    async getCurrentPosition(){
		const { Geolocation } = Plugins;
		const loc = await Geolocation.getCurrentPosition()
		localStorage.setItem('mylat',loc?.coords.latitude );
		localStorage.setItem('mylong',loc?.coords.longitude ); 
    },	
	userisloggedin() {
		localStorage.setItem('isloggedin',1 );
			
	}	
}	
}
</script>
<style>
.input-style-1.has-icon .input-icon {
    right: -30px;
}
</style>
