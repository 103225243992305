<template>
     <main class="main" v-if="!loading">	 
      <section class="section-box">
        <div class="banner-hero banner-3">
          <div class="container">
            <div class="text-center" v-if="(isloggedin == 0)&& (isgeolocalized == 0)">
              <h1 class="text-display-2 color-gray-900 mt-0"> Ypodo, l’application de signalement  <br class="d-lg-block d-none"> et de communication de votre ville !</h1>
              <div class="text-body-lead-large color-gray-500 mt-40">Pour activer l'application (Inscription et signalement) <br class="d-lg-block d-none"> Géolocalisez vous!</div>
              <div class="mt-50"><a class="btn btn-black icon-arrow-right-white mb-15" @click.prevent="getCurrentPosition()" href="#">Me Géolocaliser</a>
				<!--<a class="btn btn-default icon-arrow-right color-gray-900 ml-20 btn-mb mb-15" href="page-about-2.html">Inscription</a>-->
				</div>
            </div>
            <div class="text-center" v-if="(isloggedin == 0)&& (isgeolocalized == 1)">
              <h1 class="text-display-2 color-gray-900 mt-0"> Ypodo, l’application de signalement  <br class="d-lg-block d-none"> et de communication de votre ville !</h1>
				<div class="text-body-lead-large color-gray-500 mt-40">Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br class="d-lg-block d-none"> Sed tempor mauris eu tempus mattis. </div>
              <div class="mt-50"><a class="btn btn-black btn-red icon-arrow-right-white mb-15" v-on:click="setDestination('/signaler');" href="/login">Signaler</a>
				</div>
            </div>
            <div class="text-center" v-if="(isloggedin == 1)&& (isgeolocalized == 1)">
              <h1 class="text-display-2 color-gray-900 mt-0"> Ypodo, l’application de signalement  <br class="d-lg-block d-none"> et de communication de votre ville !</h1>
              <div class="mt-50"><a class="btn btn-black btn-red icon-arrow-right-white mb-15" href="/signaler">Signaler</a>
				<!--<a class="btn btn-default icon-arrow-right color-gray-900 ml-20 btn-mb mb-15" href="page-about-2.html">Inscription</a>-->
				</div>
            </div>			
          </div>
        </div>
      </section>
      <div class="section-box mt-40" v-if="(isloggedin == 0)&& (isgeolocalized == 0)">
        <div class="container">
          <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-10">
              <div class="box-image"><a class="popup-youtube btn-play-video btn-play-middle" href="https://player.vimeo.com/video/667682034?h=b42fc784d1&autoplay=1&title=0&byline=0&portrait=0"></a><img class="img-responsive bdrd-16" src="assets/imgs/page/homepage3/img-banner-video.png" alt="Ypodo"></div>
            </div>
            <div class="col-lg-1"></div>
          </div>
        </div>
      </div>
<!-- new -->	  
      <div class="section-box mt-40">
        <!--<div class="container"><span class="tag-1 color-orange-900">Hot for this Summer</span>
          <h2 class="text-heading-1 color-gray-900 mt-30">Explore Top Destiations</h2>
          <p class="text-body-lead-large color-gray-600 mt-20">We find the absolute best prices on hotels &amp; flights<br class="d-lg-block d-none"> then we pass these savings directly to you.</p>
        </div>-->
        <div class="container" v-if="isgeolocalized == 1">
          <div class="row" v-if="entities && (entities.length > 0)">
			<div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" v-for="entity in entities" v-bind:key="entity.id" >
              <div class="product-item-2 product-item-3 wow animate__animated animate__fadeIn" data-wow-delay=".1s"><a :href="'/commune/'+entity.id">
                  <div class="product-image"><img :src="entity.background_url"></div></a>
                <div class="product-info">
                  <a :href="'/commune/'+entity.id">
                    <h3 class="text-body-lead color-gray-900">{{entity.name}}</h3></a>
                  <div class="d-flex mt-20">
                    <p>{{ truncte (entity.slogan, 90, '...')}}</p>
                  </div>
                  <div class="d-flex mt-30">
                    <div class="button-add text-end"><a class="btn btn-explorer" :href="'/commune/'+entity.id">Découvrir</a></div>
                  </div>
                </div>
              </div>
            </div>
		</div>
	</div>
	</div>
<!-- end new -->	  	  	  
      <div class="section-box mt-30" v-if="isgeolocalized == 1">
        <div class="container">
          <div class="row" v-if="isloggedin == 1">
            <div class="col-lg-1 col-sm-1 col-12"></div>
            <div class="col-lg-10 col-sm-10 col-12 text-center">
              <h2 class="text-heading-1 color-gray-900 mb-10">Mes communes préférées</h2>
              <p class="text-body-lead-large color-gray-600 mt-20 mb-40">Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br class="d-lg-block d-none"> Sed tempor mauris eu tempus mattis.</p>
				<div class="mt-50"><a class="btn btn-black icon-arrow-right-white mb-15" href="#">Gérer</a>
				</div>
			</div>
            <div class="col-lg-1 col-sm-1 col-12"></div>
          </div>
          <div class="row" v-if="isloggedin == 0">
            <div class="col-lg-1 col-sm-1 col-12"></div>
            <div class="col-lg-10 col-sm-10 col-12 text-center">
              <h2 class="text-heading-1 color-gray-900 mb-10">Lorem ipsum dolor sit amet</h2>
              <p class="text-body-lead-large color-gray-600 mt-20 mb-40">Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br class="d-lg-block d-none"> Sed tempor mauris eu tempus mattis.</p>
			</div>
            <div class="col-lg-1 col-sm-1 col-12"></div>
          </div>		  
			<!-- begin listing-->
			<div class="row"  v-if="posts && (posts.length > 0)">
			<!-- begin element repetitif-->
			<div class="col-lg-12 col-sm-12 col-12" v-for="post in posts" v-bind:key="post.id" >
			<div class="swiper-slide mt-40" style="width: 100%">
				<div class="block-news new">
                    <div class="item-news">
                      <div class="row">
                        <div class="col-xl-6 col-lg-12">
							<a href="#">
							<div v-if="post.image_url" class="img-news bg-image" v-bind:style="{ 'background-image': 'url(' + post.image_url + ')' }"></div>
							<div v-else class="img-news bg-image" v-bind:style="{ 'background-image': 'url(' + post.entity.background_url + ')' }"></div>
							</a>
                        </div>
                        <div class="col-xl-6 col-lg-12">
                          <div class="right-news card-grid-style-4 mb-20"><span class="tag-dot">{{post.postcategory.name}}</span>
							<a class="text-heading-4" href="#">{{post.name}}</a>
                           <p class="text-body-text color-gray-500 text-desc-fix-h">{{post.introduction}}</p> 
                            <div class="blog-img-user">
                              <div class="img-user img-user-round"><img :src="post.entity.logo_url" alt="Ypodo"></div>
                              <h4 class="text-heading-6 color-gray-900">{{post.entity.name}}</h4>
                              <p class="text-body-small color-gray-500">{{ post.created_at | moment('DD/MM/YYYY')}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
			</div>
			<!-- end element repetitif-->		  
			</div>
			<!-- end listing-->
        </div>
      </div>
      <section class="section-box">
        <div class="container mt-120">
          <div class="row">
            <div class="col-lg-6 col-sm-12 block-img-we-do"><img class="bdrd-16 img-responsive" src="assets/imgs/page/homepage3/img-2.jpg" alt="Ypodo"></div>
            <div class="col-lg-6 col-sm-12 block-we-do"><span class="tag-1 bg-6 color-green-900">What We Do, What You Get</span>
              <h3 class="text-heading-1 mt-30">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h3>
              <p class="text-body-lead-large color-gray-600 mt-30"> 
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempor mauris eu tempus mattis. Phasellus feugiat nisi non augue egestas finibus. Nam non enim porttitor risus ullamcorper tincidunt ac vitae est. Phasellus placerat lorem justo, ac maximus sapien vehicula vel. Ut at laoreet libero, ut venenatis mauris. Donec iaculis lorem sed lectus malesuada malesuada. Sed a massa vel dolor venenatis aliquam.
			</p>
              <div class="row"> 
                <div class="col-lg-12 col-sm-12 col-12 mt-50">
					<a class="btn btn-black icon-arrow-right-white mb-15" href="/contact">Contactez-nous</a>	
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
</template>

<script>
import AppConstants from '@/config'
export default {
  name: 'Home',
  data() {
    return{
			loading: true,
			maintenance: false,
			destination:'/signaler',
			entities:[],
			mylat:'',
			mylong:'',
			firstConnection: true,
			p1:'',
			p2:1,
			p3:'',
			posts:[],
			ititle:'',
			icontent:'',
			iimage: '',			
			alerts:[],
			colors:['bg-green1-dark','bg-gray2-dark','bg-blue2-dark','bg-yellow1-dark', 'bg-green2-dark', 'bg-magenta2-dark', 'bg-green1-dark', 'bg-plum-dark',	'bg-gray2-dark', 'bg-yellow1-dark', 'bg-green1-dark', 'bg-teal-dark','bg-yellow1-dark',	'bg-magenta2-dark',	'bg-magenta1-dark', 'bg-orange-dark','bg-teal-dark', 'bg-green2-dark', 'bg-red2-dark','bg-pink1-dark','bg-blue2-dark', 'bg-facebook','bg-red2-dark','bg-blue1-dark','bg-google','bg-green2-dark','bg-teal-dark','bg-green1-dark','bg-brown1-dark','bg-green1-dark','bg-brown2-dark','bg-teal-dark', 'bg-gray2-dark', 'bg-red1-dark', 'bg-gray2-dark','bg-green1-dark','bg-teal-dark', 'bg-red1-dark','bg-orange-dark','bg-green1-dark','bg-yellow1-dark','bg-magenta2-dark','bg-blue1-dark','bg-green1-dark','bg-facebook','bg-twitter','bg-blue2-dark','bg-green1-dark','bg-teal-dark','bg-yellow1-dark','bg-magenta2-dark','bg-red2-dark','bg-green1-dark','bg-blue2-dark','bg-orange-dark','bg-green1-dark','bg-magenta1-dark','bg-orange-dark']
	}
  },
  components: {
  },
  props: {
    isloggedin: {
      type: Number,
      default: 0
    },
    isgeolocalized: {
      type: Number,
      default: 0
    }	
  },
  watch: {
    isgeolocalized: function (val) {
		if (val == 1) this.getEntities()
    },
 },
  mounted() {
		this.getEntities()
  },  
  methods: {
   readMorePost(post) {
		this.ititle = post.name
		this.icontent = post.description 
		this.iimage = post.image_url	
		this.openSlider('instant-1')
    },
	openSlider(id) {
		document.getElementById(id).classList.add("menu-active");
		document.getElementById(id).style.opacity = '1';
		document.getElementById(id).style.transform =  "none";	
		/* transform: translateX(100%);*/		
	},
	closeSlider(id) {
        document.getElementById(id).classList.remove("menu-active");
		document.getElementById(id).style.opacity = '0';
		document.getElementById(id).style.transform =  "translateX(100%)";	
	},
  getPostImage(post) {
		if (post.image && post.image_url) return post.image_url
		else if (post.entity.background_url) return post.entity.background_url
		else return '/assets/images/ypodo_intro2.jpg'
  },  
  getConst(key){
		const Numbers = {
				API_URL : AppConstants.API_URL,
				API_TOKEN : AppConstants.API_TOKEN,
				API_ID: AppConstants.API_ID,
		};
        return Numbers[key];	
	},
	classObjectOld(index){
			var min = 0
			var max = 50
			var rand = Math.floor(Math.random() * (max - min + 1) + min); 
			rand = rand + index
			if (this.colors[rand].length > 0) {
					return this.colors[rand]
			} else {
				return this.colors[index]
			}
			
	},
	classObject(index){
			var min = 0
			var max = 50
			var rand = Math.floor(Math.random() * (max - min + 1) + min); 
			rand = rand + index
			if (this.colors[rand]) {
			
				return this.colors[rand]
			
			} else if (this.colors[index]){
			
				return this.colors[index]
			
			} else {
				
				return this.colors[0]
			
			}
			
	},	
	openLogin(){
		this.$emit('openlogin', '1')
	},
	verifyDisplayMode(){
		if (localStorage.getItem('p1') !== null) {
					this.p1 = localStorage.getItem('p1');
		}
		if (localStorage.getItem('p2') !== null) {
					this.p2 = localStorage.getItem('p2');
		}
		if (localStorage.getItem('p3') !== null) {
					this.p3 = localStorage.getItem('p3');
		}		
	},
	truncte (text, stop, clamp) {
		return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
	},
	getEntities() {
				this.verifyDisplayMode()
				this.loading = true
				var latitude = localStorage.getItem('mylat') ? localStorage.getItem('mylat') : null;
				var longitude = localStorage.getItem('mylong') ? localStorage.getItem('mylong') : null;
				var token = this.getConst('API_TOKEN');
				var id = this.getConst('API_ID');
				var apiUrl = this.getConst('API_URL')+"anonymous-entities"
                if(token && id) {
					
                    var apiData = { 
					"latitude": latitude,
					"longitude": longitude,
					"token" : token,
					"appid": id,
					"p1": this.p1,
					"p2": this.p2,
					"p3": this.p3
					}
					let axios = require('axios');
					axios.post(apiUrl, apiData)
					.then(response => {
					this.entities = response.data.data
					this.posts = response.data.posts
					this.alerts = response.data.alerts
					
					if (response.data.message == 'Maintenance mode') {
						this.posts = []
						this.alerts = []
						this.entities = []
						this.maintenance = true
					}
					this.loading = false
				})
				.catch(error => {
				console.log(error.message);
				})	
				} 
	},
	goTo(url) {
		location.href = url;
	},
	verifyFirstConnection() {
		if ((localStorage.getItem('mylat') === null) ||(localStorage.getItem('mylong') === null) ||(this.entities == [])) {
					this.firstConnection = true;
		} else {
					this.firstConnection = false;
		}
	},
    getCurrentPosition(){
			this.$emit('activegeoloc');
    },	
	setDestination(d){
		this.destination = d
		localStorage.setItem('destination',this.destination );
	},	
  }
}
</script>	
<style>

</style>

