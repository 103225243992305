<template>
    <!-- Footer-->
    <footer class="footer mt-50">
      <div class="container">
        <div class="footer-bottom mt-20">
          <div class="row">
            <div class="col-md-9"><span class="color-gray-400 text-body-lead">&copy; Ypodo {{thisYear}} Tous droits réservés</span><a class="text-body-text color-gray-400 ml-50" target="_blank" href="https://www.ypodo.com/privacy">Politique de Confidentialité</a><a class="text-body-text color-gray-400 ml-50" target="_blank" href="https://www.ypodo.com/conditions">Conditions générales d'utilisation</a></div>
            <div class="col-md-3 text-center text-lg-end text-md-end">
              <div class="footer-social"><a class="icon-socials icon-facebook" href="https://facebook.com" target="_blank"></a><a class="icon-socials icon-twitter" href="https://twitter.com" target="_blank"></a><a class="icon-socials icon-instagram" href="https://www.instagram.com" target="_blank"></a><a class="icon-socials icon-linkedin" href="https://www.linkedin.com" target="_blank"></a></div>
            </div>
          </div>
        </div>
      </div>
    </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return{
	thisYear: '',
	}
  },
  components: {
  },  
  created() {
     
  },  
  mounted() {
	this.findYear()
  },   
  methods: {
	findYear() {
		this.thisYear = new Date().getFullYear();
	}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
