import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
 
  {
    path: '/login',
    name: 'Login',
	component: Login
		
  }, 
  {
    path: '/createaccount',
    name: 'CreateAccount',
	component: () => import('../views/CreateAccount.vue')
		
  }, 
  {
    path: '/forgot',
    name: 'Forgot',
	component: () => import('../views/Forgot.vue')
		
  },   
  {
    path: '/success',
    name: 'Success',
	component: () => import('../views/Success.vue')
		
  },  
   {
    path: '/successout',
    name: 'Successout',
	component: () => import('../views/Successout.vue')
		
  },  
  {
    path: '/about',
    name: 'About',
	component: () => import('../views/About.vue')
		
  },
  {
    path: '/categories',
    name: 'Categories',
	component: () => import('../views/Categories.vue')
		
  },  
  {
    path: '/contact',
    name: 'Contact',
	component: () => import('../views/Contact.vue')
		
  },  
  {
    path: '/voice',
    name: 'Voice',
	component: () => import('../views/Voice.vue')
		
  },  
  {
    path: '/signaler',
    name: 'Signaler',
	component: () => import('../views/Signaler.vue')
		
  },
  {
    path: '/article/:id',
    name: 'Article',
	component: () => import('../views/Article.vue'),
	props: true	
  },
  {
    path: '/commune/:id',
    name: 'Commune',
	component: () => import('../views/Commune.vue')	,
	props: true
  },
  {
    path: '/poll/:id',
    name: 'Sondage',
	component: () => import('../views/Poll.vue'),
	props: true
  }, 
  {
    path: '/book/:id',
    name: 'Book',
	component: () => import('../views/Book.vue'),
	props: true
  }, 
  {
    path: '/book/:id/:cat',
    name: 'Book',
	component: () => import('../views/Book.vue'),
	props: true
  },  
  {
    path: '/calendar/:id',
    name: 'Calendar',
	component: () => import('../views/Calendar.vue'),
	props: true	
  },  
  {
    path: '/signalements',
    name: 'Signalements',
	component: () => import('../views/Signalements.vue')	
  },
  {
    path: '/signalement/:id',
    name: 'Signalement',
	component: () => import('../views/Signalement.vue'),	
	props: true
	// Pass route.params to props
  },
  {
    path: '/compte',
    name: 'Compte',
	component: () => import('../views/Compte.vue')	
  },
  {
    path: '/inhabitant',
    name: 'Inhabitant',
	component: () => import('../views/Inhabitant.vue')	
  } 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
