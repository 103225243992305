<template>
  <div id="app">
	<Header :isloggedin="isloggedin" :isgeolocalized="isgeolocalized" />
    <router-view @openlogin="emitOpenLoginMenu" @openaccountsuccess="emitOpenAccountSuccess" @loginaccount="userIsLoggedOut" @activegeoloc="getCurrentPosition()" @opencategories="emitOpenCategoriesMenu" @logout="logout" @alert="showAlert" :isloggedin="isloggedin" :isgeolocalized="isgeolocalized" />
    <Footer :isloggedin="isloggedin" :isgeolocalized="isgeolocalized" />
  </div>
</template>
<script>
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'
//import Uploader from '@/components/Uploader.vue'
import { Capacitor, Plugins } from '@capacitor/core'
//import carousel from 'vue-owl-carousel'
import firebase from 'firebase'
//import { FCM } from "capacitor-fcm"
import AppConstants from '@/config'
var firebaseConfig = {
    apiKey: "AIzaSyCQA0E23YOarEx0or2X6pdCNFA1LhhCtbo",
    authDomain: "ypodo-ea9df.firebaseapp.com",
    projectId: "ypodo-ea9df",
    storageBucket: "ypodo-ea9df.appspot.com",
    messagingSenderId: "84950120895",
    appId: "1:84950120895:web:ee74ab991b72b8da96490a",
    measurementId: "G-SG5PGNPBB2"
  };  
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// @ is an alias to /src
export default {
	name: 'App',
  data() {
    return{ 
		uploadedFiles: null,
        showUploader: false,
		forgotemail:'',
		fcmToken:'',
		destination:'/signaler',
		passwordFieldType: 'password',
		alertId: 0,
		alertTitle:'',
		canPhoto: true,
		canVideo: true,
		canFile: true,
		canMedia: true,
		//maxFileSize : 1600000, // 1.6 MO : 30 sec de video
		maxFileSize : 16000000, // 16 MO
		categories:[],
		doubles:[],
		subcategories:[],
		selectedCategoryId: 0,
		selectedCategoryName:'',
		loadingCategories: false,
		loading: false,
		newUser: {},
		errors: {},
		conditions: false,
		isloggedin: 0,
        isgeolocalized: 0,		
		signalSent: 0, 
		input: {
                    username: "",
                    password: ""
        },
		loginError: {
                    username: 0,
                    password: 0
        },		
		inputSignal: {
					category: '',
					description: '',
					latitude : '', 
					longitude : ''
        },		
		signalError: {
					message:'',
					category: 0,
					description: 0,
					geolocation : 0,
					files:0,
					photos:0,
					videos:0
        },
		user: {},
		submitting: false,
		polling: null,
		code:'',
		messages: 0
		}
  }, 
  components: {Header, Footer},
  beforeDestroy () {
	clearInterval(this.polling)
  },
  created () {
	
  }, 
  mounted() {
		///this.initAllForTest()
		this.isUserLoggedIn()
		this.handleBack()
		this.handleFcm()
		this.pushInit()
		this.pollData()
  }, 
  methods: {
	initAllForTest() {
		localStorage.removeItem('mylat' );
		localStorage.removeItem('mylong' );
		localStorage.removeItem('token')		
		localStorage.removeItem('id')
		localStorage.removeItem('email')
		localStorage.removeItem('firstname')
		localStorage.removeItem('name')
		localStorage.removeItem('address')
		localStorage.removeItem('zip')
		localStorage.removeItem('city')
		localStorage.removeItem('fcm')
		localStorage.removeItem('mip');
		localStorage.removeItem('isloggedin' );
		localStorage.removeItem('destination' );
		this.isloggedin = 0;
		this.isgeolocalized = 0;
			
	},
	getRandomInt(max) {
			return Math.floor(Math.random() * max);
	}, 
	pollData () {
		if (localStorage.getItem('mip') === null) {
				var mip = this.getRandomInt(999999999999);
				localStorage.setItem('mip', mip );
		}		
		this.polling = setInterval(() => {

			//this.getCode() 


			
		}, 300000) /* 5 min*/
	}, 
	getCode() {
				if (!this.isloggedin) return false
				var token = this.getConst('API_TOKEN');
				var id = this.getConst('API_ID');
				var apiUrl = this.getConst('API_URL')+"user-code"	
				var api_token = localStorage.getItem('token')
				let axios = require('axios');
				if (api_token) {
					var apiData = {
					"token" : token,
					"appid": id
					}
					axios.post(apiUrl, apiData,
					{
						headers: {
							'Authorization': 'Bearer '+ api_token,
						}
					})
					.then(response => {
						if (response.data.message == 'OK') {
							var sent = 0
							if (response.data.code)
							this.code = response.data.code
						
							if (response.data.messages)
							this.messages = response.data.messages
						
							if ((this.code) && (this.code !== '')){
									if (localStorage.getItem('code') === null) {
											localStorage.setItem('code',this.code );
									} else {
										if (localStorage.getItem('code') == this.code) {
										console.log(this.code)
										} else { 
											sent = 1
											this.emitCode()
											localStorage.setItem('code',this.code );
										}
									}
							}
							if ((this.messages) && (this.messages !== 0) && (sent == 0)){
							
									if (localStorage.getItem('messages') === null) {
											localStorage.setItem('messages',this.messages );
									} else {
										if (localStorage.getItem('messages') == this.messages) {
										console.log(this.messages)
										} else { 
											this.emitMessages()
											localStorage.setItem('messages',this.messages );
										}
									}
							}							
						}
					})
					.catch(error => {
						console.log(error.response.data.errors)
						this.loading = true
					})
					}				
	},	
	displayUploader() {
		this.showUploader = true
	},
	endUpload(f) {
		this.uploadedFiles = f
		this.hideUploader()
			var i;
			console.log('this.uploadedFiles');
			for( i = 0; i < this.uploadedFiles.length; i++ ){
				
				console.log(this.uploadedFiles[i]);
			}		
		
		
	},
	cancelUpload() {
		this.uploadedFiles = null
		this.hideUploader()
	},	
	hideUploader() {
		this.showUploader = false
	},
	async showCapAlert(notification) {
		const { Modals } = Plugins;
		await Modals.alert({
			title: notification.title,
			message: notification.body
		});
	},
	pushInit() {
	if (Capacitor.platform != 'web') {
	
	///.platform could be for example web ios android
	const { PushNotifications } = Plugins;
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then(result => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
   // THIS FUNCTION IS NOT FIRED:
    PushNotifications.addListener('registration',
      (token) => {
	
		this.registerUserToken(token.value)
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error) => {
        console.log('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification) => {
        // this.handleNotification(notification);
		this.showCapAlert(notification)
        // Need to present notification in app on android I think.
        //this.handleForegroundNotification(notification);
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification) => {
        ///this.handleNotification(notification.notification);
		this.showCapAlert(notification.notification)
      }
    );
	}
  },	
	registerUserToken(currentToken) {
		var device = Capacitor.platform
		if (this.isloggedin == 1) {
		if ((localStorage.getItem('fcm') != currentToken) || (localStorage.getItem('fcm') == '') || (localStorage.getItem('fcm') == null)) {
				localStorage.setItem('fcm',currentToken);
				var token = this.getConst('API_TOKEN');
				var id = this.getConst('API_ID');
				var apiUrl = this.getConst('API_URL')+"update-fcm"	
				var api_token = localStorage.getItem('token')
				let axios = require('axios');
				if (api_token) {
					var apiData = {
					"fcm" : currentToken,
					"device": device,
					"token" : token,
					"appid": id
					}
					axios.post(apiUrl, apiData,
					{
						headers: {
							'Authorization': 'Bearer '+ api_token,
						}
					})
					}						
				}
		}
	},
	handleFcm() {
		if (Capacitor.platform == 'web') {
		var fcmToken = 'BAG5i4bhzXyThO8YmHkM3P6n0iDlR8OjBNrka86eSCQNYkenixxc8WWp9FsS3rRnVpa5L33UfAyRWTq9GV0lAGw';
		firebase.messaging().usePublicVapidKey(fcmToken);
		firebase.messaging().getToken({ vapidKey: fcmToken })
		.then((currentToken) => {
			if (currentToken) {
				this.registerUserToken(currentToken)
			} else {
				console.log('No registration token available. Request permission to generate one.');
			}
			}).catch((err) => {
				console.log('An error occurred while retrieving token. ', err);
			})
		}	
	},
	switchVisibility() {
		this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
    }, 
	async handleBack() {

	},
	getConst(key){
		const Numbers = {
				API_URL : AppConstants.API_URL,
				API_TOKEN : AppConstants.API_TOKEN,
				API_ID: AppConstants.API_ID,
		};
        return Numbers[key];	
	},
	resetFileUpload() {
		this.signalError = {
					message:'',
					category: 0,
					description: 0,
					geolocation : 0,
					files:0,
					photos:0,
					videos:0
				};
	},
	getCategoryNameFromId(id) {
			for (let item of this.categories) {
			if (item.id == id) {
				this.selectedCategoryName = item.name
			}
			}
	},
	setCategory(id) {
		this.selectedCategoryId = id
		this.getCategoryNameFromId(id)
		//this.selectedCategoryName = this.categories[id-1].name
		var token = this.getConst('API_TOKEN');
		var appid = this.getConst('API_ID');
		var apiUrl = this.getConst('API_URL')+"anonymous-subcategories"
		var apiData = {
			'category': id,			
			'token' : token,
			'appid': appid
		}
		let axios = require('axios');
			axios.post(apiUrl, apiData)
			.then(response => {
				if (response.data.message == 'OK') {
					this.subcategories = response.data.data
					this.closeMenu('menu-categories')
					this.openMenu('menusignaler')
				} else {
						this.errors = response.data.errors
				}
			})

		// trouver subcategories
  
	},
	showAlert(e){
	if (!e) return false;
	},
    hideAlert(){
	},
	logout() {
		localStorage.removeItem('token')
		localStorage.removeItem('id')
		localStorage.removeItem('email')
		localStorage.removeItem('firstname')
		localStorage.removeItem('name')
		localStorage.removeItem('address')
		localStorage.removeItem('zip')
		localStorage.removeItem('city')
		localStorage.removeItem('fcm')
		localStorage.setItem('isloggedin',0 );
		this.userIsLoggedOut()
	},  
	closeLogin(){
		var id = 'menu-signin';
		this.closeMenu(id)
	},	
	openLogin() {
			this.closeLogin()
			var id = 'menu-signin';
			document.getElementById(id).classList.add("menu-active");
			document.getElementById(id).style.opacity = '1';
			document.getElementById(id).style.display = 'block'	
			document.getElementById(id).style.bottom =  "80px";
			document.getElementById(id).style.height =  "310px";
	
	},	
	openMenu(id) {
		document.getElementById(id).classList.add("menu-active");
		document.getElementById(id).style.opacity = '1';
		document.getElementById(id).style.display = 'block'	
		if (id == 'menu-signin-success') {
		document.getElementById(id).style.bottom =  "80px";
		} else {
		document.getElementById(id).style.bottom =  "80px";
		}
	},
	closeMenu(id){
		document.getElementById(id).style.opacity = '0'		
		document.getElementById(id).style.bottom =  "40px";
		document.getElementById(id).classList.remove("menu-active");
		document.getElementById('footer-bar').classList.remove("footer-menu-hidden");
		document.body.classList.remove('modal-open'); 
		var elems = document.querySelectorAll(".menu-hider");
		[].forEach.call(elems, function(el) {
				el.classList.remove("menu-active");
				el.classList.remove("menu-active-clear");
		});	
		if (id == 'menusignaler') {
		this.resetFileUpload();		
		}
		
	},
	setDestination(d){
		this.destination = d
		localStorage.setItem('destination',this.destination );
	},
	closeAndRedirectMenu(id){
		this.closeMenu(id)
		if (id == 'menu-notification') {
		location.href = '/signalements'
		} else if (id == 'menu-messages') {
		location.href = '/signalements'
		} else {
		if (this.destination)	 {
			/*location.href = '/home'*/
			location.href = this.destination
		}
		}
	},
	getCategories() {
		this.loadingCategories = true
		var latitude = localStorage.getItem('mylat');
		var longitude = localStorage.getItem('mylong');
		var token = this.getConst('API_TOKEN');
		var id = this.getConst('API_ID');
		var apiUrl = this.getConst('API_URL')+"anonymous-categories"
		var vm = this;
		if(latitude != "" && longitude != "") {
                    var apiData = { 
					"latitude": latitude,
					"longitude": longitude,
					"token" : token,
					"appid": id
					}
					
					let axios = require('axios');
					axios.post(apiUrl, apiData)
					.then(response => {
					if (response.data.message == 'OK') {
							vm.categories = response.data.data
							if (response.data.double && (response.data.double.length > 0)) 
								vm.doubles = response.data.double
							vm.loadingCategories = false	
					}
		})
		.catch(error => {
				console.log(error.message);
		})	
		} 
	},	
	emitOpenCategoriesMenu(e) {
		//if (this.categories.length == 0)
		this.closeMenu('menusignaler');
		this.getCategories();
		if (e == '1') {
			var id = 'menu-categories';
			this.openMenu(id)
		}
	},
	emitOpenAccountSuccess(e) {
		if (e == '1') {
			var id = 'menu-signup-success';
			this.openMenu(id)
		}
	},
	emitCode() {
		
			var id = 'menu-notification';
			this.openMenu(id)
		
	},
	emitMessages() {
		
			var id = 'menu-messages';
			this.openMenu(id)
		
	},	
	emitOpenLoginMenu(e) {
		if (e == '1') {
			this.closeLogin()
			this.openLogin()
			this.destination = '/signaler'
		}
	},	
	
	onChangeSubCategory(event){
		if (event.target.value != 0) this.signalError.category = 0
	},
	onInputDescription(event){
		if (event.target.value.length > 0) this.signalError.description = 0
	},	
	signal() {
		var token = this.getConst('API_TOKEN');
		var id = this.getConst('API_ID');
		var apiUrl = this.getConst('API_URL')+"create-report"	
		var api_token = localStorage.getItem('token')
		let axios = require('axios');
		if (!api_token) return false; 
		var signallatitude = localStorage.getItem('signallatitude');
		var signallongitude = localStorage.getItem('signallongitude');
		signallatitude = signallatitude.replace(/,/g, '.')
		signallongitude = signallongitude.replace(/,/g, '.')	 
		signallatitude = parseFloat(signallatitude)
		signallongitude = parseFloat(signallongitude)
		var description = this.inputSignal.description
		var category =  this.inputSignal.category
		var error = 0;
		if (!category) {
			this.signalError.category = 1;
			this.signalError.message = 'Veuillez choisir une sous-catégorie.';
			error = 1;
		}
		if (!description) {
			this.signalError.description = 1;
			this.signalError.message = 'Veuillez décrire le signalement.';
			error = 1;
		}		
		if (!error && api_token != "" && signallatitude!= "" && signallongitude!= "" && description !="" && category!="" && !this.submitting) {
			this.submitting = true
			let formData = new FormData();
			var nb;
			var i;
			if ( this.uploadedFiles) {
			if ( this.uploadedFiles.length ) {
			if ( this.uploadedFiles.length > 0 ) {
			for( i = 0; i < this.uploadedFiles.length; i++ ){
					nb = i+1;
					formData.append('file' + nb, this.uploadedFiles[i].data);
			}		
			}
			}
			}
			formData.append('token', token);
			formData.append('appid',id);
			formData.append('category', category);
			formData.append('description', this.inputSignal.description);
			formData.append('followed', 1);
			formData.append('latitude', signallatitude);
			formData.append('longitude', signallongitude);	
			axios.post(apiUrl, formData,
			{
				headers: {
					'Authorization': 'Bearer '+ api_token,
					'Content-Type': 'multipart/form-data'
				}
			}
			)
			.then(response => {
						this.signalSent = 1
						this.resetFileUpload()
						this.closeMenu('menusignaler')
						//this.openMenu("signalersuccess")	
						console.log(JSON.stringify(response.data))
						if (response.data.message == 'OUT') {
							this.$router.push('Successout')
						} else {
							this.$router.push('Success')
						}
				})
				.catch(error => {
					this.signalSent = 0
					this.submitting = false
					console.log(JSON.stringify(error))
				})
	} else {
		this.submitting = false
	}
	},
	isUserLoggedIn() {
			this.isloggedin = 0;
			if (localStorage.getItem('isloggedin') === null) {
					this.isloggedin = 0;
					localStorage.setItem('isloggedin',this.isloggedin );
			} else {
					if (localStorage.getItem('isloggedin') == 1) {
						this.isloggedin = 1;
					} else { 
						this.isloggedin = 0;
					}
			}
			this.isGeoLocalized()
			
	},
	isGeoLocalized() {
			
			if ((localStorage.getItem('mylat') === null) ||(localStorage.getItem('mylong') === null)) {
			this.isgeolocalized = 0;
			} else {
			
			this.isgeolocalized = 1;
			}

	},	
    async getCurrentPosition(){
			const { Geolocation } = Plugins;
			const loc = await Geolocation.getCurrentPosition()
			if (loc) localStorage.setItem('mylat',loc.coords.latitude );
			if (loc) localStorage.setItem('mylong',loc.coords.longitude ); 
			if (this.isgeolocalized == 0) {
					this.isgeolocalized = 1;
					//window.location.reload();
			}
			this.isgeolocalized = 1;
		
    },	
	userisloggedin() {
		this.isloggedin = 1;
		localStorage.setItem('isloggedin',this.isloggedin );
			
	},
	userIsLoggedOut() {
		this.isloggedin = 0;
		localStorage.setItem('isloggedin',this.isloggedin );
	},	
	login() {
                if(this.input.username != "" && this.input.password != "") {
                    var token = this.getConst('API_TOKEN');
					var id = this.getConst('API_ID');
					var apiUrl = this.getConst('API_URL')+"login"
				
					var apiData = { 
					"email": this.input.username,
					"password": this.input.password,
					"role" : "ROLE_INHABITANT",
					"token" : token,
					"appid": id
					}
					let axios = require('axios');
					axios.post(apiUrl, apiData)
					.then(response => {
						if (response.data.message == 'OK') {
						this.user = response.data.data	
						localStorage.setItem('token', this.user.api_token )
						localStorage.setItem('id', this.user.id )
						localStorage.setItem('email', this.user.email )
						localStorage.setItem('firstname', this.user.firstname )
						localStorage.setItem('name', this.user.name )
						localStorage.setItem('address', this.user.address )
						localStorage.setItem('zip', this.user.zip )
						localStorage.setItem('city', this.user.city )
						localStorage.setItem('fcm', this.user.fcm )
						this.loginError.username = 0;
						this.loginError.password = 0;
                        this.userisloggedin()
						this.closeMenu('menu-signin')
						this.openMenu('menu-signin-success')
						} else {
						this.loginError.username = 1;
						this.loginError.password = 1;
						}
				})
				.catch(error => {
					console.log(JSON.stringify(error));
					this.loginError.username = 1;
					this.loginError.password = 1;
				})	
                } else {
					this.loginError.username = 1;
					this.loginError.password = 1;
                }
	},
	forgot(){
		var token = this.getConst('API_TOKEN');
		var id = this.getConst('API_ID');
		var apiUrl = this.getConst('API_URL')+"anonymous-forgotemail"
		if((token != "") && (id != "") && (this.forgotemail != "")) {
                    var apiData = { 
					"forgotemail": this.forgotemail,
					"token" : token,
					"appid": id
					}
					let axios = require('axios');
					axios.post(apiUrl, apiData)
					.then(response => {
					if (response.data.message == 'OK') {
							this.closeMenu('menu-forgot')
							this.openMenu('menu-forgot-success')
					}
		})	
		} 	
			
	}
}	
}
</script>
<style>
</style>
