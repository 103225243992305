import en from './en';
import fr from './fr';

export default {
    en: {
        message: en
    },
    fr: {
        message: fr
    }
}